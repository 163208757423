/*
 * 
 * Autore:  Fiorelli Luigi
 * Data:    01/09/2017
 * Agg :    04/10/2017
 * V   :    1.1.5
 */
var logged = function(){
    return RAD.data.logged;
}
var log_ok = function(loginVal){
    App.io.log("Logging Okq - Logged");
    App.data.logged = true;
    try{
        App.io.setNome(loginVal.nome);
        App.io.setCognome(loginVal.cognome);
        App.io.setTelefono(loginVal.telefono);
        App.io.setAc_pr(loginVal.ac_pr);
        App.io.setAc_ne(loginVal.ac_ne);
    } catch(err){
        
    }
    App.fun.loginNumberZero();
    App.option.save();
    App.outGoing();
    if(App.data.firstLogin){
        try{
            App.data.funStartUser();
        }catch(err){
            App.io.log(err);
        }
    }
};
var log_fail = function(){
    App.io.log("Logging Refuse");
    App.option.save();
    App.outGoing();
};
var reg_ok = function(){
    App.data.logged = true;
    try{
        App.io.setNome(RAD.data.nome);
        App.io.setCognome(RAD.data.cognome);
        App.io.setTelefono(RAD.data.telefono);
        App.io.setAc_pr(RAD.data.ac_pr);
        App.io.setAc_ne(RAD.data.ac_ne);
    } catch(errTry){
        
    }
    
    App.fun.loginNumberZero();
    App.option.save();
    App.outGoing();
    try{
        App.data.funStartUser();
    }catch(err){
        App.io.log(err);
    }
    try{
        App.data.funTryReg_ok();
    }catch(err){
        App.io.log(err);
    }
};
var reg_fail = function(err){
    try{
        App.data.funTryReg_fail(err);
    } catch(errTry){
        
    }
};
var trib_ok =function(trib){
    App.io.log("Request Lista Tributi Ok");
    //trib_show(trib);
    App.data.tribListAgg = true;
    App.data.tribList = trib;
    App.option.save();
    App.outGoing();
};
var trib_fail = function(){
    App.io.log("Request Lista Tributi Fail")
    App.outGoing();
};
var cart_list_ok= function(cart_list){
    var n = cart_list.length;
    for(i=0;i<n;i++){
        a = cart_list[i]["id_ttr"];
        try{
            b = App.data.tribList[0]["desc"];
            for(j=0;j<App.data.tribList.length;j++){
                if(App.data.tribList[j]["id_ttr"] == a){
                    b = App.data.tribList[j]["desc"];
                }
            }
            cart_list[i]["ttr"] = b;
        }
        catch(err){
            App.io.log(err);
        }
    }
    App.page.cartelle.data.cartelle_list = cart_list;
    App.page.cartelle.data.cartelleAgg = true;
    App.page.cartelle.showCart();
    App.io.log("Request Lista Cartelle Ok");
    App.option.save();
    App.outGoing();
}
var cart_list_fail = function(){
    //Codice da implementare
    App.io.log("Request Lista Cartelle Fail");
    App.page.cartelle.data.cartelleAgg = false;
    App.outGoing();
}
var cart_add_ok = function(res){ //deprecated
    App.io.log("Request Add Cartella Ok");
    App.page.cartelle.data.cartelleAgg = false;
    clean_cartForm();
    App.outGoing();
};
var cart_add_fail = function(error){ //deprecated
    App.io.log("Request Add Cartella Fail - ",error);
    App.outGoing();
    window.setTimeout("App.page.cartelle.sendNewCart()",100);
};

var trib_show = function(trib){
    trib_list = document.getElementById('elenco_tributi');
    if(trib_list != null){
        for( i in trib ) {
            trib_list.add( new Option( trib[i]["desc"],trib[i]["id_ttr"] ) ); 
        };
        return true;
    } else {
        return false;
    }
    
};
var cart_check_data = function(cart){
    var error = false;
    
    //Check
    if(     !isInt(cart["id_ttr"]) || cart["id_ttr"]<0)                         { error = 2; }
    if(     !isInt(cart["tr_anno"]) || cart["tr_anno"] < 1970)                  { error = 3; }
    if(     (!isFloat(cart["tr_importo"]) && !isInt(cart["tr_importo"])) || 
            cart["tr_importo"]<1)                                               { error = 4; }
    if(     !checkData(cart["tr_ruolo"]))                                       { error = 5; }
    if(     !checkData(cart["tr_notifica"]))                                    { error = 6; }
    
    return error;
};
var clean_cartForm = function(){ //deprecated
    $("#elenco_tributi").val("");
    $("#estr_anno").val("");
    $("#estr_importo").val("");
    $("#estr_ruolo").val("");
    $("#estr_notifica").val("");
    $("#estr_note").val("");
};
var sendNewCartAppFunction= function(){
    RAD.req_cart_add(   App.page.cartelle.data.newCart_ok,
                        App.page.cartelle.data.newCart_fail,
                        App.page.cartelle.data.newCart);
};

var check_email = function(str){
    var isEmail = true;
    if(str.length < 5){
        isEmail = false;
    }
    var s1
    if(isEmail){
        try{
            s1 = str.split("@");
            //console.log(s1[0].length + " " + s1[0]);
            //console.log(s1[1].length + " " + s1[1]);
            if(s1[0].length<2){ isEmail = false; }
            if(s1[1].length<2){ isEmail = false; }
        } catch(err){
            isEmail = false;
        }
    }
    if(isEmail){
        try{
            var s2 = s1[1].split(".");
            //console.log(s2[0].length + " " + s2[0]);
            //console.log(s2[1].length + " " + s2[1]);
            if(s2[0].length<2){ isEmail = false; }
            if(s2[1].length<2){ isEmail = false; }
        } catch(err){
            isEmail = false;
        }
    }

    return isEmail;
};